import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ApiService } from '../api.service';
import { finalize, map } from 'rxjs/operators';

export interface IReference {}

@Injectable({
  providedIn: 'root',
})
export class ReferenceService {
  private subscription: Subscription = new Subscription();
  private isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  isLoading: Observable<boolean> = this.isLoadingSubject.asObservable();
  constructor(private api: ApiService) {}

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  createReference(payload: { title: string; content: string; cover: string }) {
    this.isLoadingSubject.next(true);
    return this.api.post('references', payload).pipe(
      map((response: any) => response.data),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  getReference(id: number) {
    this.isLoadingSubject.next(true);
    return this.api.get(`references/${id}`).pipe(
      map((response: any) => response.data),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  getReferences(skip: number, limit: number) {
    this.isLoadingSubject.next(true);
    return this.api
      .get('references', {
        params: {},
      })
      .pipe(
        map((response: any) => response.data),
        finalize(() => this.isLoadingSubject.next(false))
      );
  }

  deleteReference(id: number) {
    this.isLoadingSubject.next(true);
    return this.api.delete(`references/${id}`).pipe(
      map((response: any) => response.data),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  updateReference(payload: {
    title: string;
    content: string;
    cover: string;
    id: number;
  }) {
    this.isLoadingSubject.next(true);
    const { id, ...param } = payload;
    return this.api.patch(`references/${id}`, param).pipe(
      map((response: any) => response.data),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }
}
