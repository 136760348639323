import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/api/api.service';
import {
  IAchievement,
  IResBodyDataAchievementDetails,
  IResBodyDataAchievements,
  IResBodyDataCompanies,
  IResBodyDataCreateAchievement,
  IResUpdateDataAchievements,
} from './achivements.types';

@Injectable({
  providedIn: 'root',
})
export class AchievemetService {
  constructor(private apiService: ApiService) { }

  encodeDescription(en = '', hk = ''): string {
    return JSON.stringify({ en, hk });
  }

  decodeDescription(description: string): string | false {
    if (description.length) {
      return JSON.parse(description);
    }
    return false;
  }

  isJsonString(str: string): boolean {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  getCompanies(): Promise<IResBodyDataCompanies> {
    return this.apiService.get({
      url: '/companies/allCompanies',
    });
  }

  getDetailsAchivement(
    achievementId: string
  ): Promise<Achievement.IResBodyDataAchievementDetails> {
    return this.apiService.get({
      url: `/achievement/${achievementId}`,
    });
  }

  getAchivements(
    limit: number = 10,
    skip: number = 0
  ): Promise<Achievement.IResBodyDataAchievements> {
    return this.apiService.get({
      url: 'achievement/list',
      params: {
        limit,
        skip,
      },
    });
  }

  updateAchivements(
    frmData: Pick<IAchievement, 'title' | 'description' | 'attempts' | 'averageScore' | 'bestScore' | 'company'> & { id: string }
  ): Promise<IResUpdateDataAchievements> {
    return this.apiService.patch({
      url: 'achievement',
      body: frmData,
    });
  }

  createAchivements(
    frmData: Achievement.IAchievement
  ): Promise<Achievement.IResBodyDataCreateAchievement> {
    return this.apiService.post({
      url: 'achievement',
      body: frmData,
    });
  }

  deleteDetailsAchivement(
    achievementId: string
  ): Promise<Achievement.IResBodyDataAchievementDetails> {
    return this.apiService.delete({
      url: `/achievement/${achievementId}`,
    });
  }
}
