import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ApiService } from '../api.service';
import { finalize, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CommunityService {
  private subscription: Subscription = new Subscription();
  private isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  isLoading: Observable<boolean> = this.isLoadingSubject.asObservable();

  constructor(private api: ApiService) {}

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  createCommunity(payload: {
    title: string;
    content: string;
    cover: string;
    author: string;
  }) {
    this.isLoadingSubject.next(true);
    return this.api.post('communities', payload).pipe(
      map((response: any) => response.data),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  getCommunities() {
    this.isLoadingSubject.next(true);
    return this.api.get('communities').pipe(
      map((response: any) => response.data),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  getCommunity(id: number) {
    this.isLoadingSubject.next(true);
    return this.api.get(`communities/${id}`).pipe(
      map((response: any) => response.data),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  updateAcademy(payload: {
    title: string;
    content: string;
    cover: string;
    author: string;
    id: number;
  }) {
    this.isLoadingSubject.next(true);
    const { id, ...param } = payload;
    return this.api.patch(`communities/${id}`, param).pipe(
      map((response: any) => response.data),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  deleteCommunity(id: number) {
    this.isLoadingSubject.next(true);
    return this.api.delete(`communities/${id}`).pipe(
      map((response: any) => response.data),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }
}
