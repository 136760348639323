import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ApiService } from '../api.service';
import { finalize, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NewsService {
  private subscription: Subscription = new Subscription();
  private isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  isLoading: Observable<boolean> = this.isLoadingSubject.asObservable();

  constructor(private api: ApiService) {}

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  createNews(payload: { title: string; content: string; author: string }) {
    this.isLoadingSubject.next(true);
    return this.api.post('news', payload).pipe(
      map((response: any) => response.data),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  getNews() {
    this.isLoadingSubject.next(true);
    return this.api.get('news').pipe(
      map((response: any) => response.data),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  getNew(id: number) {
    this.isLoadingSubject.next(true);
    return this.api.get(`news/${id}`).pipe(
      map((response: any) => response.data),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  updateNews(payload: {
    title: string;
    content: string;
    author: string;
    id: number;
  }) {
    this.isLoadingSubject.next(true);
    const { id, ...param } = payload;
    return this.api.patch(`news/${id}`, param).pipe(
      map((response: any) => response.data),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }
  deleteNews(id: number) {
    this.isLoadingSubject.next(true);
    return this.api.delete(`news/${id}`).pipe(
      map((response: any) => response.data),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }
}
